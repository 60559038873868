<template>
  <div class="margin-top-30 padding-bottom-30">
    <div style="margin: 0 5vw;">
      <div class="grid-content-div">
        <div class="div-item"><h3>粉丝概况</h3></div>
        <div class="div-item">
          <span>统计时间</span>
          <el-select v-model="searchData.timeType"
                     @change="changeTimeType"
                     placeholder="时间类型"
                     style="width: 120px;margin-left: 15px;">
            <el-option
              v-for="item in timeTypeArray"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
          <el-date-picker
            :clearable="false"
            :editable="false"
            key="multiSelect"
            style="margin-left: 15px;"
            range-separator="~"
            class="date-picker-pointer"
            value-format="yyyy-MM-dd"
            @change="changeTime"
            v-model="searchData.startTime"
            :picker-options="timeTypeArray[searchData.timeType-1].option"
            :format="timeTypeArray[searchData.timeType-1].format"
            :readonly="timeTypeArray[searchData.timeType-1].readonly"
            :type="timeTypeArray[searchData.timeType-1].type"
            placeholder="选择时间"
          >
          </el-date-picker>
        </div>
        <div class="div-item line-border padding-30 text-center" title="">
            <div class="flex">
              <div class="flex-1">
                  <div class="f18">
                    <span>访客数</span>
                    <el-tooltip class="item" effect="dark" content="统计时间内，店铺所有页面（包括店铺主页、单品页）被访问的去重人数，一个人在筛选时间范围内访问多次只记为一个" placement="right">
                      <i class="el-icon-warning-outline"></i>
                    </el-tooltip>
                  </div>
                  <div><span class="f20 color-orange">{{theDateBasicData.visitorCount || 0}}</span></div>
                  <div><span>{{timeTypeArray[searchData.timeType - 1].lastTimeDesc}}</span><span class="margin-left">{{lastDateBasicData.visitorCount || 0}}</span></div>
              </div>
              <div class="flex-1">
                  <div class="f18">
                    <span>浏览量</span>
                    <el-tooltip class="item" effect="dark" content="统计时间内，店铺所有页面（包括店铺主页、单品页）被访问的次数，一个人在统计时间内访问多次记为多次。" placement="right">
                      <i class="el-icon-warning-outline"></i>
                    </el-tooltip>
                  </div>
                  <div><span class="f20 color-orange">{{theDateBasicData.viewsCount || 0}}</span></div>
                  <div><span>{{timeTypeArray[searchData.timeType - 1].lastTimeDesc}}</span><span class="margin-left">{{lastDateBasicData.viewsCount || 0}}</span></div>
              </div>
              <div class="flex-1">
                  <div class="f18">
                    <span>新增关注粉丝数</span>
                    <el-tooltip class="item" effect="dark" content="统计时间内，新关注的粉丝去重人数" placement="right">
                      <i class="el-icon-warning-outline"></i>
                    </el-tooltip>
                  </div>
                  <div><span class="f20 color-orange">{{theDateBasicData.attentionCount || 0}}</span></div>
                  <div><span>{{timeTypeArray[searchData.timeType - 1].lastTimeDesc}}</span><span class="margin-left">{{lastDateBasicData.attentionCount || 0}}</span></div>
              </div>
              <div class="flex-1">
                  <div class="f18">
                    <span>新增绑定会员数</span>
                    <el-tooltip class="item" effect="dark" content="统计时间内，新绑定店铺的粉丝去重人数" placement="right">
                      <i class="el-icon-warning-outline"></i>
                    </el-tooltip>
                  </div>
                  <div><span class="f20 color-orange">{{theDateBasicData.bindCount  || 0}}</span></div>
                  <div><span>{{timeTypeArray[searchData.timeType - 1].lastTimeDesc}}</span><span class="margin-left">{{lastDateBasicData.bindCount || 0}}</span></div>
              </div>
            </div>
        </div>
        <div class="div-item">
          <span>关注粉丝概况及趋势</span>
        </div>
        <div class="div-item line-border padding-30 text-center" title="">
          <div class="flex">
            <div class="flex-1">
              <div class="f18">
                <span>关注粉丝总数</span>
                <el-tooltip class="item" effect="dark" content="截至到统计时间的最后一天，关注店铺的粉丝总人数" placement="right">
                  <i class="el-icon-warning-outline"></i>
                </el-tooltip>
              </div>
              <div><span class="f20 color-orange">{{theDateBasicData.attentionTotal || 0}}</span></div>
            </div>
            <div class="flex-1">
              <div class="f18">
                <span>新增关注粉丝数</span>
                <el-tooltip class="item" effect="dark" content="统计时间内，新关注的粉丝去重人数" placement="right">
                  <i class="el-icon-warning-outline"></i>
                </el-tooltip>
              </div>
              <div><span class="f20 color-orange">{{theDateBasicData.attentionCount || 0}}</span></div>
              <div><span>{{timeTypeArray[searchData.timeType - 1].lastTimeDesc}}</span><span class="margin-left">{{lastDateBasicData.attentionCount || 0}}</span></div>
            </div>
            <div class="flex-1">
              <div class="f18">
                <span>取关粉丝数</span>
                <el-tooltip class="item" effect="dark" content="统计时间内，取消关注的粉丝去重人数" placement="right">
                  <i class="el-icon-warning-outline"></i>
                </el-tooltip>
              </div>
              <div><span class="f20 color-orange">{{theDateBasicData.cancelAttentionCount || 0}}</span></div>
              <div><span>{{timeTypeArray[searchData.timeType - 1].lastTimeDesc}}</span><span class="margin-left">{{lastDateBasicData.cancelAttentionCount || 0}}</span></div>
            </div>
            <div class="flex-1">
              <div class="f18">
                <span>净增关注粉丝数</span>
                <el-tooltip class="item" effect="dark" content="统计时间内，实际增长的粉丝人数，新增关注粉丝数减去取关粉丝数" placement="right">
                  <i class="el-icon-warning-outline"></i>
                </el-tooltip>
              </div>
              <div><span class="f20 color-orange">{{theDateBasicData.addedAttentionCount || 0}}</span></div>
              <div><span>{{timeTypeArray[searchData.timeType - 1].lastTimeDesc}}</span><span class="margin-left">{{lastDateBasicData.addedAttentionCount || 0}}</span></div>
            </div>
          </div>
        </div>
        <div class="div-item" title="关注粉丝概况及趋势 的折线图" style="height: 400px;">
          <div id="fansLineChart" style="width: 100%;height: 100%;"></div>
        </div>
        <div class="div-item">
          <span>绑定粉丝概况及趋势</span>
        </div>
        <div class="div-item line-border padding-30 text-center" title="">
          <div class="flex">
            <div class="flex-1">
              <div class="f18">
                <span>绑定会员总数</span>
                <el-tooltip class="item" effect="dark" content="截至到统计时间的最后一天，绑定店铺的粉丝总人数" placement="right">
                  <i class="el-icon-warning-outline"></i>
                </el-tooltip>
              </div>
              <div><span class="f20 color-orange">{{theDateBasicData.bindTotal || 0}}</span></div>
            </div>
            <div class="flex-1">
              <div class="f18">
                <span>新增绑定会员数</span>
                <el-tooltip class="item" effect="dark" content="统计时间内，新绑定的粉丝去重人数" placement="right">
                  <i class="el-icon-warning-outline"></i>
                </el-tooltip>
              </div>
              <div><span class="f20 color-orange">{{theDateBasicData.bindCount || 0}}</span></div>
              <div><span>{{timeTypeArray[searchData.timeType - 1].lastTimeDesc}}</span><span class="margin-left">{{lastDateBasicData.bindCount || 0}}</span></div>
            </div>
            <div class="flex-1">
              <div class="f18">
                <span>解绑会员数</span>
                <el-tooltip class="item" effect="dark" content="统计时间内，解除绑定的粉丝去重人数" placement="right">
                  <i class="el-icon-warning-outline"></i>
                </el-tooltip>
              </div>
              <div><span class="f20 color-orange">{{theDateBasicData.cancelBindCount || 0}}</span></div>
              <div><span>{{timeTypeArray[searchData.timeType - 1].lastTimeDesc}}</span><span class="margin-left">{{lastDateBasicData.cancelBindCount || 0}}</span></div>
            </div>
            <div class="flex-1">
              <div class="f18">
                <span>净增绑定会员数</span>
                <el-tooltip class="item" effect="dark" content="统计时间内，实际增长的粉丝人数，新增绑定粉丝数减去解绑粉丝数" placement="right">
                  <i class="el-icon-warning-outline"></i>
                </el-tooltip>
              </div>
              <div><span class="f20 color-orange">{{theDateBasicData.addedBindCount || 0}}</span></div>
              <div><span>{{timeTypeArray[searchData.timeType - 1].lastTimeDesc}}</span><span class="margin-left">{{lastDateBasicData.addedBindCount || 0}}</span></div>
            </div>
          </div>
        </div>
        <div title="绑定粉丝概况及趋势 的折线图" style="height: 400px;">
          <div id="bindLineChart" style="width: 100%;height: 100%;"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  import dayjs from 'dayjs'

  import {isEmpty,getTimeTypeArray} from '../../../assets/js/utils'
  import {URL} from '../../../config'

  export default {
    name: "fansDataProfile",
    data(){
      return {
        searchData:{
          startTime: dayjs().startOf('day').subtract(1,'day').format('YYYY-MM-DD'),
          timeType:"1",
        },
        timeTypeArray:getTimeTypeArray(),
        fansLineChart:{},
        bindLineChart:{},
        fansLineChartData:{
          xAxis:[],
          totalData:[],
          increaseData:[],
          cancelData:[],
        },
        bindLineChartData:{
          xAxis:[],
          totalData:[],
          increaseData:[],
          cancelData:[],
        },
        theDateBasicData:{},
        lastDateBasicData:{},
      }
    },

    methods:{
      drawFansLine(){
        // 基于准备好的dom，初始化echarts实例
        this.fansLineChart = this.$echarts.init(document.getElementById('fansLineChart'));
        let option = {
          tooltip: {
            trigger: 'axis'
          },
          legend: {
            data: ['关注粉丝总数', '新增关注粉丝数', '取关粉丝数']
          },
          grid: {
            left: '2%',
            right: '3%',
            bottom: 'auto',
            containLabel: true
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: this.fansLineChartData.xAxis
          },
          yAxis: {
            name: '人数',
            type: 'value'
          },
          series: [
            {
              name: '关注粉丝总数',
              type: 'line',
              smooth: true,
              data: this.fansLineChartData.totalData
            },
            {
              name: '新增关注粉丝数',
              type: 'line',
              smooth: true,
              data: this.fansLineChartData.increaseData
            },
            {
              name: '取关粉丝数',
              type: 'line',
              smooth: true,
              data: this.fansLineChartData.cancelData
            },
          ]
        };

        // 绘制图表
        this.fansLineChart.setOption(option);
      },
      drawBindLine(){
        // 基于准备好的dom，初始化echarts实例
        this.bindLineChart = this.$echarts.init(document.getElementById('bindLineChart'));
        let option = {
          tooltip: {
            trigger: 'axis'
          },
          legend: {
            data: ['绑定会员总数', '新增绑定会员数', '解绑会员数']
          },
          grid: {
            left: '2%',
            right: '3%',
            bottom: 'auto',
            containLabel: true
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: this.bindLineChartData.xAxis
          },
          yAxis: {
            name: '人数',
            type: 'value'
          },
          series: [
            {
              name: '绑定会员总数',
              type: 'line',
              smooth: true,
              data: this.bindLineChartData.totalData
            },
            {
              name: '新增绑定会员数',
              type: 'line',
              smooth: true,
              data: this.bindLineChartData.increaseData
            },
            {
              name: '解绑会员数',
              type: 'line',
              smooth: true,
              data: this.bindLineChartData.cancelData
            },
          ]
        };
        // 绘制图表
        this.bindLineChart.setOption(option);
      },
      /**
       * 拼接搜索参数
       */
      jointSearchData(){
        let data = {};
        data = Object.assign(data,this.searchData);
        let startTime = this.searchData.startTime;
        let timeType = this.searchData.timeType;
        if(timeType == 1){//自然日
          if(!isEmpty(startTime)){
            startTime = new Date(startTime + " 00:00:00").getTime();
          }
        }else if( timeType == 2){//自然周
          let weekObj = new Date(startTime);
          let date = weekObj.getDay();
          if(date == 1){
            startTime = weekObj.getTime();
          }else{
            startTime = weekObj.getTime() - 24*60*60*1000;
          }
        }else if(timeType == 3){
          startTime = dayjs(new Date(startTime)).startOf("M").format("YYYY-MM-DD");
          startTime = new Date(startTime + " 00:00:00").getTime();
        }
        data.startTime = startTime;
        return data;
      },
      changeTime(){
        this.getStatisticData();
      },
      changeTimeType(){
        let timeType = this.searchData.timeType;
        this.searchData.startTime = this.timeTypeArray[timeType-1].value;
        this.getStatisticData();
      },
      getStatisticData(){
        return new Promise((resolve, reject) => {
          let data = this.jointSearchData();
          this.loading = true;
          this.axios.get(URL.suppStatistic.fanStatistic,{params:data}).then(res=>{
            this.theDateBasicData = res.data.basics.theDate;
            this.lastDateBasicData = res.data.basics.lastDate;
            console.log("this.basicData",this.basicData);
            let len = res.data.diagramList.length;
            this.fansLineChartData.xAxis = [];
            this.fansLineChartData.totalData = [];
            this.fansLineChartData.increaseData = [];
            this.fansLineChartData.cancelData = [];
            this.bindLineChartData.xAxis = [];
            this.bindLineChartData.totalData = [];
            this.bindLineChartData.increaseData = [];
            this.bindLineChartData.cancelData = [];
            if(len > 0){
              res.data.diagramList.forEach(item=>{
                this.fansLineChartData.xAxis.push(item.dateStr);
                this.bindLineChartData.xAxis.push(item.dateStr);
                this.fansLineChartData.totalData.push(item.attentionTotal);
                this.fansLineChartData.increaseData.push(item.attentionCount);
                this.fansLineChartData.cancelData.push(item.cancelAttentionCount);

                this.bindLineChartData.totalData.push(item.bindTotal);
                this.bindLineChartData.increaseData.push(item.bindCount);
                this.bindLineChartData.cancelData.push(item.cancelBindCount);

              });
            }
            this.drawFansLine();
            this.drawBindLine();
            resolve(res);
          }).catch(res=>{
          }).finally(res=>{
            this.loading = false;
          })
        })
      },
    },
    mounted(){
      this.getStatisticData()
      window.onresize = ()=>{
        this.fansLineChart.resize();
        this.bindLineChart.resize();
      }
    },
  }
</script>

<style lang="less" scoped>
  .grid-content-div{
    .div-item{
      margin: 20px auto;
    }
    .line-border{
      border: 1px solid #ebebeb;
      border-radius: 3px;
      color: #606266;
      font-size: 14px;
    }
  }

  .color-orange {
    color: orange;
  }
  .f18 {
    font-size: 18px;
  }
  .f20 {
    font-size: 20px;
  }
  .f24 {
    font-size: 24px;
  }


</style>
